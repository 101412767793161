<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12">
          <b-overlay
            :show="showOver"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <p id="cancel-label">กรุณารอสักครู่...</p>
              </div>
            </template>
            <b-button
              class="btn btn-gradient-primary mb-1 mr-50"
              @click="$router.go(-1)"
            >
              <i class="fas fa-arrow-left mr-25" /> {{ $t("returnToList") }}
            </b-button>
            <b-button
              class="btn btn-gradient-success mb-1 ml-25 mr-50"
              @click="Submit()"
            >
              <i class="far fa-save mr-50" /> {{ $t("saveData") }}
            </b-button>

            <b-row class="match-height">
              <b-col md="12">
                <div class="card">
                  <div class="header-cardx p-2">
                    <h3 class="mb-50">
                      {{ $t("details") }}
                    </h3>
                  </div>

                  <div class="p-2">
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          label-for="h-paecelCode"
                          label-cols="2"
                          label-cols-lg="2"
                          label-cols-md="2"
                          class="mb-50"
                        >
                          <template v-slot:label>
                            {{ $t("mnemonic") }}
                          </template>
                          <b-form-input v-model="keyword" type="text" />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group
                          label-for="payfor-select"
                          label-cols="2"
                          label-cols-lg="2"
                          label-cols-md="2"
                          class="mb-50"
                        >
                          <template v-slot:label>
                            รายการฝากจ่ายเถาเปา
                          </template>
                          <v-select
                            id="payfor-select"
                            v-model="payforselect"
                            :options="itemspayfor"
                            label="itemnumber_text"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <div class="table-container">
                          <b-table
                            :fields="fieldsRefund"
                            :items="Refund"
                            show-empty
                            class="table-mobile"
                          >
                            <template #empty="">
                              <b-table-empty-slot>
                                <div class="text-center my-2">
                                  <img
                                    src="@/assets/images/anan-img/svg/doc_empty.svg"
                                    alt="empty"
                                    height="60px"
                                  />
                                  <p class="text-muted">No Data</p>
                                </div>
                              </b-table-empty-slot>
                            </template>

                            <template #cell(number)="data">
                              {{ data.index + 1 }}
                            </template>

                            <template #cell(order_list)="data">
                              <div class="text-center">
                                <b-form-input v-model="data.item.order_id" />
                              </div>
                            </template>
                            <!-- <template #cell(payfor)="data">
                            <div class="text-center">
                              
                            </div>
                          </template> -->
                            <template #cell(date_add)="data">
                              <flat-pickr
                                v-model="data.item.date_approve"
                                class="form-control"
                                :placeholder="$t('selectDateTime')"
                                :config="flatpickrConfig"
                              />
                            </template>

                            <template #cell(file_refer)="data">
                              <div
                                v-if="data.item.file_refer"
                                class="preview-image-container mr-25 d-flex justify align-items-center"
                              >
                                <img
                                  :src="data.item.file_refer"
                                  alt="Preview"
                                  width="100px"
                                  height="50px"
                                  @click="showPicture(data.item.file_refer)"
                                />
                                <div class="preview-image-overlays p-0">
                                  <i
                                    class="fas fa-times-circle cursor-pointer"
                                    @click="
                                      removeImage((data.item.file_refer = null))
                                    "
                                  />
                                </div>
                              </div>
                              <div
                                v-else
                                class="input-file-container cursor-pointer"
                              >
                                <i class="fal fa-plus cursor-pointer mr-50" />{{
                                  $t("key-9")
                                }}
                                <input
                                  id="img-file"
                                  ref="fileInput"
                                  type="file"
                                  class="input-file"
                                  accept="image/*"
                                  multiple
                                  @change="
                                    handleFileChanges($event, data.index)
                                  "
                                />
                              </div>
                            </template>

                            <template #cell(price)="data">
                              <b-form-input
                                v-model="data.item.price"
                                type="number"
                                :formatter="(value) => Math.abs(value)"
                                @input="checkprice()"
                              />
                            </template>

                            <template #cell(action)="data">
                              <div v-if="data.index == 0" class="text-center">
                                <b-button
                                  class="btn-success btn-sm"
                                  @click="additem()"
                                >
                                  +
                                </b-button>
                              </div>
                              <div v-if="data.index !== 0" class="text-center">
                                <feather-icon
                                  icon="TrashIcon"
                                  class="text-danger cursor-pointer"
                                  @click="removeRow(data.index)"
                                />
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                      <b-col md="12">
                        <div class="d-flex justify-content-end mt-2">
                          <span class="font-weight-bold h4 mt-2"
                            >{{ $t("key-32") }} : ¥ {{ Commas(total) }}</span
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
      </div>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BTable,
  BOverlay,
  // BFormSelect,
} from "bootstrap-vue";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import flatPickr from "vue-flatpickr-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import ThaiLocale from "flatpickr/dist/l10n/th";
import vSelect from "vue-select";
// import moment from 'moment-timezone'
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";
import SideBar from "../component/SideBar.vue";

export default {
  name: "LineNotify",
  components: {
    SideBar,
    // ThisHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    flatPickr,
    VueImageLightboxCarousel,
    // BFormSelect
    vSelect,
    BOverlay,
    // BFormFile,
  },
  data() {
    return {
      images: [],
      showOver: false,
      showLightbox: false,
      keyword: "",
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      file: null,
      total: 0,
      Refund: [
        {
          order_id: null,
          date_approve: null,
          file_refer: null,
          price: 0,
          price_ban: 0,
          status: 1,
        },
      ],
      filecusimg: null,
      flatpickrConfig: {
        dateFormat: "Y-m-d  H:i",
        enableTime: true,
        time_24hr: true,
        locale: ThaiLocale.th,
      },
      receivedDate: null,
      General: [],
      payforselect: null,
      itemspayfor: [],
    };
  },
  computed: {
    fieldsRefund() {
      return [
        {
          key: "number",
          label: this.$t("sequence"),
          thStyle: { width: "5%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "order_list",
          label: this.$t("orderList"),
          thStyle: { width: "15%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "date_add",
          label: this.$t("key-151"),
          thStyle: { width: "20%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "file_refer",
          label: this.$t("key-152"),
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "price",
          label: `${this.$t("price")}¥`,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "action",
          label: this.$t("manage"),
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
    },
  },
  mounted() {
    this.GetTAO();
    // this.getGeneral()
  },
  methods: {
    GetTAO() {
      this.showOver = true;
      this.$http
        .get("/Payforchina/getData/TAO")
        .then((response) => {
          this.itemspayfor = response.data;
          this.showOver = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Submit() {
      this.showOver = true;
      // console.log(this.Refund)
      const index = this.Refund.findIndex(
        (items) => items.file_refer === null || items.date_approve === null
      );
      if (index >= 0) {
        this.showOver = false;
        return this.SwalError("กรอกข้อมูลให้ครบถ้วน");
      }
      if (!this.payforselect) {
        this.showOver = false;
        return this.SwalError("กรุณาเลือกรายการฝากจ่ายเถาเปา");
      }
      const obj = {
        keyword: this.keyword,
        products: this.Refund,
        pay_products: this.total,
        rate: this.payforselect,
        // pay_productsth: this.total * this.General.rate_payment,
      };
      this.$http
        .post("/Payforchina/Store/refund", obj)
        .then((response) => {
          if (response.data.status) {
            this.$router.go(-1);
            this.Success(this.$t("key-260"));
            this.showOver = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.showOver = false;
          this.SwalError(err.response.data.mes);
        });
    },
    async getGeneral() {
      try {
        const { data: res } = await this.$http.get("/general/getData");
        if (res.status) {
          this.General = res.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    additem() {
      this.Refund.push({
        order_id: null,
        date_approve: null,
        file_refer: null,
        price: 0,
        price_ban: 0,
        status: 1,
      });
    },
    checkprice() {
      // this.Refund[index].price_ban = this.Refund[index].price * this.General.rate_payment
      this.total = this.Refund.reduce(
        (total, acc) => total + Number(acc.price),
        0
      );
    },
    removeRow(index) {
      this.Refund.splice(index, 1);
    },
    // checktime(data) {
    //   return moment(data).format('YYYY-MM-DD')
    // },
    handleFileChanges(event, data) {
      const filescus = event.target.files;
      const reader = new FileReader();
      const filepath = filescus[0];
      reader.onload = (e) => {
        // console.log(e.target.result)
        // console.log(e.target.result)
        this.Refund[data].file_refer = e.target.result;
        // console.log(this.filecusimg)
        // e.target.result
        // console.log(this.namemap)
      };
      reader.readAsDataURL(filepath);
    },
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: picture };
      this.images.push(img);
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@media only screen and (max-width: 600px) {
  .table-mobile {
    width: 1000px !important;
  }
  .justify-content-end {
    text-align: right; /* ให้แน่ใจว่าเนื้อหาภายในอยู่ที่ขวาสุด */
  }
}
</style>
